import * as React from "react";
import { useEffect } from "react";
import "./layout/styles.scss";
import {
  TitleSection,
  SEOMetaTags,
  BlogBreadcrumbs,
  CallToAction,
  GreyFooter,
  FundingBanner,
  PrevButton,
  NextButton
} from "../components";
import VideoCard from "../components/VideoCard/VideoCard";
import MainMenu from "../components/MainMenu/MainMenu";
import { IOurClientsSpeak } from "./interfaces";

const OurClientsSpeakList = (data: IOurClientsSpeak): JSX.Element => {

  useEffect(() => {
    const bodyStyle = document.body.style;
    bodyStyle.opacity = "1";
    bodyStyle.transition = "opacity 0.3s";
  }, []);

  let { hasNextPage, hasPrevPage, nextPage, prevPage, items, seoMetaTags, menu, footer, lang, urlName, cta, c2c, pageTitle, banner } = data.pageContext;
  const pageTitleData = pageTitle && pageTitle.find(el => el.page === "our-clients-speak");
  const pageName = pageTitleData && pageTitleData.name;
  const description = pageTitleData && pageTitleData.description;

  let slug;

  const getUrlName = (language) => {
    switch (language) {
      case "pl":
        return "klienci-o-nas";
      case "en":
        return "our-clients-speak";
      case "es":
        return "nuestros-clientes-hablan";
      default:
        return "our-clients-speak";
    }
  };

  const alternateLocalesData = items[0]._allTitleLocales.filter(el => el && el.locale !== lang);
  const alternateLocales = alternateLocalesData.map(el => el.locale);
  const alternateLinks = alternateLocales && alternateLocales.map(locale => locale && {
    locale: locale,
    link: `https://pushpushgo.com/${locale}/${getUrlName(locale)}/1/`,
  });
  return (
    <>
      <MainMenu menu={menu} lang={lang} urlName={urlName} c2c={c2c}/>
      <SEOMetaTags lang={lang} tags={seoMetaTags} pageName={pageName} alternateLinks={alternateLinks}/>
      <TitleSection propertyReference={[{ key: "title", value: pageName }, { key: "blog", value: "blog-version" }, {
        key: "color",
        value: "green-version",
      }, { key: "paragraph", value: `${description}` }]}/>
      <section className="subpage-section">
        <div className="content-limiter">
          <BlogBreadcrumbs pages={[{ key: "page", value: pageName, url: `/${lang}/${urlName}/1` }]}/>
          <div className="cards-grid">
            {items.map(testimonial => <VideoCard key={Math.random() + new Date().toDateString()} {...testimonial} />)}
          </div>
          <nav>
            {hasPrevPage && <PrevButton lang={lang} path={`/${lang}/${slug(lang)}/${prevPage}`}/>}
            {hasNextPage && <NextButton lang={lang} path={`/${lang}/${slug(lang)}/${nextPage}`}/>}
          </nav>
        </div>
      </section>
      <CallToAction cta={cta}/>
      <GreyFooter footer={footer} lang={lang}/>
      <FundingBanner banner={banner}/>
    </>
  );
};
export default OurClientsSpeakList;
